<script>
import { watch, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { locale as dxLocale, loadMessages } from 'devextreme/localization'
import esMessages from 'devextreme/localization/messages/es.json'
import enMessages from 'devextreme/localization/messages/en.json'
import theme from 'devextreme/ui/themes'

export default {
  setup() {
    const { locale } = useI18n()
    const i18nTitle = ref('')
    provide('i18nTitle', i18nTitle)
    const route = useRoute()
    const colorMode = useColorMode()

    const displayedTitle = computed(() => {
      return i18nTitle.value || route.meta.title
    })

    function setDxLocale(i18nLocale) {
      dxLocale(i18nLocale)
      if (i18nLocale === 'es') {
        loadMessages(esMessages)
      } else {
        loadMessages(enMessages)
      }
    }

    function setDxTheme() {
      theme.current(`sa-${colorMode?.value ?? 'light'}`)
    }

    watch(locale, (newLocale) => {
      setDxLocale(newLocale)
    })

    watch(colorMode, () => {
      setDxTheme()
    })

    onMounted(() => {
      // Ensure the correct locale is set when the component mounts
      setDxLocale(locale.value)
      setDxTheme()
    })

    return { displayedTitle }
  },
}
</script>

<template>
  <TairoCollapseLayout>
    <template #toolbar-title>
      {{ displayedTitle }}
    </template>
    <slot />
  </TairoCollapseLayout>
</template>
